body {
  overflow: hidden;
}

.App {
  overflow: hidden;
}

.AppHeader {
  height: 76px;
  border-bottom: 1px solid rgb(216, 220, 222);
  box-shadow: rgba(4, 68, 77, 0.15) 0px 0px 10px 0px;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 100;
}

.AppBody {
  margin-top: 76px;
  height: 424px;
  overflow: scroll
}